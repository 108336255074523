






























import { Manager } from '@/api/interfaces/manager'
import { defineComponent } from '@vue/composition-api'
import { mapActions, mapState } from 'vuex'
import { ValidationObserver } from 'vee-validate'
import { Company } from '@/api/interfaces/company'

export default defineComponent({
  name: 'SelectSearchManagers',
  components: {
    SelectSearch: () => import('../m-select-search.vue'),
    CreateEditManager: () => import('../dialog/createEditManager.vue'),
  },
  props: {
    value: {
      type: Object,
      required: false,
      default: () => null,
    },
    company: {
      type: Object as () => Company,
      required: false,
      default: () => null,
    },
    branch: {
      type: Object as () => Company,
      required: false,
      default: () => null,
    },
  },
  watch: {
    async company () {
      if (this.company) {
        await this.load(this.company)
      }
      if (this.value) {
        if (!this.findManagerInList(this.value['@id'])) {
          this.$emit('input', null)
        }
      }
    },
    // load gestionnaires de branche + remove them if clear branch value
    async branch (newValue) {
      if (this.company) {
        if (newValue) {
          await this.load([this.company, this.branch])
        } else if (!newValue) {
          await this.load(this.company)
        }
      }
    },
  },
  data () {
    return {
      dialogGestionnaire: false,
      isSubmiting: false,
      managerEdit: {},
    }
  },
  async created () {
    if (this.company) {
      if (this.branch) {
        await this.load([this.company, this.branch])
      } else {
        await this.load(this.company)
      }
    }
  },
  computed: {
    ...mapState('managerList', {
      managerList: 'list',
    }),
    companyName (): string {
      return this.company ? (this.company.name as string) : ''
    },
  },
  methods: {
    ...mapActions('managerList', {
      load: 'load',
      post: 'post',
    }),
    onInput (uri: string) {
      const manager = this.findManagerInList(uri)
      this.$emit('input', manager)
    },
    findManagerInList (uri: string) {
      return (this.managerList as Array<Manager>).find(x => x['@id'] === uri)
    },
    getValidationObserver () {
      return this.$refs.observer as InstanceType<typeof ValidationObserver>
    },
    toggleModal () {
      this.dialogGestionnaire = !this.dialogGestionnaire
    },
    editManager () {
      this.toggleModal()
      this.managerEdit = { ...this.value }
    },
    onClose () {
      this.dialogGestionnaire = false
      this.managerEdit = {}
    },
    onManagerCreated (manager: Manager) {
      this.$emit('input', manager)
    },
    async updateManager (manager: Manager) {
      this.$emit('input', manager)
      if (this.branch) {
        await this.load([this.company, this.branch])
      } else {
        await this.load(this.company)
      }
    },
    itemText (item: Manager): string {
      return item.lastName + ' ' + (item.firstName || '')
    },
  },
})
